<template>
    <div>
        <nav-bar title="订单详情" url="/order"></nav-bar>

        <!--订单详情-->
        <div class="top">
            <div class="title">
                <div class="title-text">订单详情</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <div class="list">
                <div class="left">姓名</div>
                <div class="right">{{info.order_name}}</div>
            </div>
            <div class="list">
                <div class="left">身份证号</div>
                <div class="right">{{info.order_sfz}}</div>
            </div>
            <div class="list">
                <div class="left">手机号</div>
                <div class="right">{{info.order_tel}}</div>
            </div>
            <div class="list">
                <div class="left">订单号</div>
                <div class="right">{{info.order_no}}</div>
            </div>
            <div class="list">
                <div class="left">支付金额</div>
                <div class="right">￥{{info.order_money}}</div>
            </div>

            <div class="list" v-if="info.order_refund_money">
                <div class="left">退款金额</div>
                <div class="right">￥{{info.order_refund_money}}</div>
            </div>


            <div class="list">
                <div class="left">购买数量</div>
                <div class="right">{{info.order_number}}张</div>
            </div>
            <div class="list">
                <div class="left">订单状态</div>
                <div class="right">
                    <van-tag mark type="danger" v-if="info.order_state==0">订单取消</van-tag>
                    <van-tag mark v-if="info.order_state==1">待支付</van-tag>
                    <van-tag mark type="success" v-if="info.order_state==2">支付成功</van-tag>
                    <van-tag mark type="warning" v-if="info.order_state==3">部分退款</van-tag>
                    <van-tag mark v-if="info.order_state==4">退款完成</van-tag>
                    <van-tag mark type="danger" v-if="info.order_state==7">开始使用</van-tag>
                    <van-tag mark type="success" v-if="info.order_state==8">订单完成</van-tag>
                </div>
            </div>

            <div class="list">
                <div class="left">下单时间</div>
                <div class="right">{{formatDate(info.create_time)}}</div>
            </div>
            <div class="list" v-if="info.order_pay_time">
                <div class="left">支付时间</div>
                <div class="right">{{formatDate(info.order_pay_time)}}</div>
            </div>
            <div class="list" v-if="info.order_refund_time">
                <div class="left">退款时间</div>
                <div class="right">{{formatDate(info.order_refund_time)}}</div>
            </div>

            <div class="list" v-if="info.order_cancel_time">
                <div class="left">取消时间</div>
                <div class="right">{{formatDate(info.order_cancel_time )}}</div>
            </div>
            <div class="list" v-if="info.order_finish_time">
                <div class="left">完成时间</div>
                <div class="right">{{formatDate(info.order_finish_time)}}</div>
            </div>
        </div>


        <!-- 检票信息 -->
        <div class="top" v-if="info.order_state >= 2">
            <div class="title">
                <div class="title-text">检票信息</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <div class="list">
                <div class="left">核验码</div>
                <div class="right">{{info.order_check_code}}</div>
            </div>
            <div class="list">
                <div class="left">检票码</div>
                <div class="right" @click="image_preview([info.qr],0)">
                    <img :src="info.qr" style="height: 20px;">
                </div>
            </div>
        </div>


        <!-- 套票信息 -->
        <div class="top" v-for="(item,index) in package" :key="index">
            <div class="title">
                <div class="title-text">套票{{index+1}}</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
                <span class="refund" @click="refund(index)" v-if="item.child_state == 0 && info.order_state>=2">申请退款</span>
            </div>

            <div class="ticket">
                <div class="package-info">
                    <div class="package-name">
                        {{item.child_package_name}}
                    </div>
                    <table class="table" border="1">
                        <tr>
                            <th>数量</th>
                            <td>{{item.child_number}}张</td>
                            <th>单价</th>
                            <td>￥{{item.child_package_price}}</td>
                        </tr>

                        <tr>
                            <th>总价</th>
                            <td>￥{{item.child_money}}</td>
                            <th>套票状态</th>
                            <td>
                                <van-tag mark type="danger" v-if="item.child_state==0">未使用</van-tag>
                                <van-tag mark v-if="item.child_state==1">使用完成</van-tag>
                                <van-tag mark type="success" v-if="item.child_state==2">使用中</van-tag>
                                <van-tag mark type="warning" v-if="item.child_state==3">已退款</van-tag>
                                <van-tag mark v-if="item.child_state==4">已失效</van-tag>
                            </td>
                        </tr>
                    </table>

                    <table class="table" border="1">
                        <thead>
                            <tr>
                                <th>景点名称</th>
                                <th style="width: 50px;">状态</th>
                            </tr>
                        </thead>
                        <tr v-for="(item2,index2) in item.scenic" :key="index2">
                            <td style="text-align: left;">{{index2 + 1}}、{{item2.ordic_scenic_name}}</td>
                            <td>
                                <van-tag type="warning" v-if="item2.ordic_state == 0">未使用</van-tag>
                                <van-tag type="success" v-if="item2.ordic_state == 1">已使用</van-tag>
                                <van-tag v-if="item2.ordic_state == 2">已失效</van-tag>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="action" v-if="info.order_state==1">
            <van-button round type="primary" @click="pay" :color="config.main_color" size="large">立即支付
            </van-button>
            <van-button round type="primary" @click="cancel_order" color="#ccc" size="large" style="margin-top: 15px;">
                取消订单</van-button>
        </div>

    </div>
</template>

<script>
    import {
        package_detail,
        package_cancel,
        package_refund,
        package_pay,
        package_success,
    } from '@/api/package.js';

    import {
        ImagePreview,
        Dialog,
        Toast,
    } from 'vant';
    import { wxPay } from '@/static/js/pay.js';
    export default {
        name: 'package_detail',
        data() {
            return {
                info: {},
                package: [],
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {

            //获取订单列表
            get_data() {
                package_detail({
                    id: this.$route.query.id,
                    loading: true,
                }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data.info;
                        this.package = res.data.package;
                    }
                });
            },


            //图片预览
            image_preview(img, index) {
                ImagePreview({
                    images: img,
                    startPosition: index ? index : 0
                });
            },

            //取消订单
            cancel_order() {
                var that = this;
                Dialog.confirm({
                    title: '取消确认',
                    message: '确认要取消该订单吗？取消后不可以恢复！',
                }).then(function () {
                    package_cancel({
                        id: that.$route.query.id,
                        loading: true,
                    }).then(res => {
                        if (res.code == 1) {
                            Toast(res.msg);
                            that.get_data();
                        }
                    });
                });
            },

            //申请退款
            refund(index) {
                var info = this.package[index];
                if (info.child_state == 3) {
                    vant.Toast('该套票已退款成功！');
                    return false;
                }
                var that = this;
                Dialog.confirm({
                    title: '退款确认',
                    message: '确认要申请退款吗？',
                }).then(function () {
                    package_refund({
                        loading: true,
                        loading_text: '申请中……',
                        child_id: info.id
                    }).then(res => {
                        Toast(res.msg);
                        that.get_data();
                    });
                });
            },

            //提交支付
            pay(data) {
                var that = this;
                package_pay({
                    loading: true,
                    id: this.$route.query.id
                }).then(res => {
                    if (res.code == 1) {
                        wxPay(res.data.info).then(res2 => {
                            that.$router.replace('/package_success?id=' + res.data.id);
                        }).catch(err => {
                            that.$router.replace('/package_success?id=' + res.data.id);
                        });
                    }
                });
            },



            formatDate(time) {
                if (!time) {
                    return '';
                }
                var date = new Date(time * 1000);
                var YY = date.getFullYear() + "-";
                var MM =
                    (date.getMonth() + 1 < 10 ?
                        "0" + (date.getMonth() + 1) :
                        date.getMonth() + 1) + "-";
                var DD =
                    date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                var hh =
                    (date.getHours() < 10 ?
                        "0" + date.getHours() :
                        date.getHours()) + ":";
                var mm =
                    (date.getMinutes() < 10 ?
                        "0" + date.getMinutes() :
                        date.getMinutes()) + ":";
                var ss =
                    date.getSeconds() < 10 ?
                        "0" + date.getSeconds() :
                        date.getSeconds();
                return YY + MM + DD + " " + hh + mm + ss;
            },


            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        border-color: #ccc;
        margin-top: 10px;
    }

    .table th {
        font-weight: 700;
        padding: 8px 12px;
        font-size: 12px;
    }

    .table td {
        padding: 8px 12px;
        font-size: 12px;
    }

    .package-info {
        padding: 10px;
    }

    .package-name {
        font-size: 15px;
        font-weight: 700;
    }

    .refund {
        z-index: 2000;
        position: absolute;
        right: 15px;
        top: 0;
        color: red;
    }

    .refund span {
        position: relative !important;
        left: 0 !important;
    }

    .order-image {
        width: 90%;
        height: 60px;
    }

    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    .top .list {
        position: relative;
        height: 40px;
        line-height: 40px;
        margin-left: 10px;
        margin-right: 10px;
        font-weight: 200;
        font-size: 13px;
        width: calc(100% - 20px);
    }

    .top .list .left {
        position: absolute;
        left: 10px;
        display: inline-block;
        font-weight: 400;
    }

    .top .list .right {
        position: absolute;
        right: 10px;
        display: inline-block;
        color: #999;
        font-weight: 400;
        width: calc(100% - 70px);
        white-space: nowrap;
        text-align: right;
        height: 40px;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .top .list::after {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 200%;
        transform: scale(0.5);
        transform-origin: 0 0;
        border-bottom: 1px solid #f2f2f2;
        pointer-events: none;
    }

    .top .list:last-child::after {
        border: none;
    }


    .photo {
        width: calc(100% - 20px) !important;
        margin: 0 auto;
        background: #fff;
        text-align: center;
    }

    .action {
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 50px;
        width: calc(100% - 40px);
    }

    .van-button--primary {
        background-image: url('../../static/image/index/7.png') !important;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        font-family: 'FZCS';
    }
</style>