import {Dialog} from "vant";

export function wxPay(field) {
    return new Promise((resolve,reject)=>{
        if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(field), false);
            } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(field));
                document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(field));
            }
        }
        else {
            WeixinJSBridge.invoke('getBrandWCPayRequest', {
                    "appId": field.appId,               //公众号ID，由商户传入
                    "timeStamp": field.timeStamp,       //时间戳，自1970年以来的秒数
                    "nonceStr": field.nonceStr,         //随机串
                    "package": field.package,
                    "signType": field.signType,         //微信签名方式：
                    "paySign": field.paySign,           //微信签名
                },
                function (res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        Dialog({message: '支付成功'}).then(()=>{
                            resolve();
                        });
                    } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                        Dialog({message: '支付取消'}).then(()=>{
                            reject();
                        });
                    } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                        Dialog({message: '支付失败'}).then(()=>{
                            reject();
                        });
                    }
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                })
        }
    })
}
